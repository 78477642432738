import styled, { css } from "styled-components";

import { paddingPage } from "../styles";
import { darkBlueGrey } from "components/baseColor";
import { IS_FRAME_MOBILE } from "utils";

export const Wrapper = styled.div`
  ${paddingPage}
  padding-top: 2em;
  p {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  > h1 {
    font-family: "Open Sans";
    font-size: 4.167em;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    text-align: center;
    color: ${darkBlueGrey};
    margin: 0;
    margin-bottom: 1em;
    text-transform: uppercase;

    ${IS_FRAME_MOBILE} {
      font-size: 2em;
    }
  }

  > p {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.88px;
    color: ${darkBlueGrey};
    text-align: center;
    margin-bottom: 84px;

    ${IS_FRAME_MOBILE} {
      font-size: 14px;
    }
  }
`;

export const WrapperTable = styled.div`
  border-radius: 8px;
  border: solid 1px ${darkBlueGrey};
  display: flex;
  margin-bottom: 80px;
  ${IS_FRAME_MOBILE} {
    flex-direction: column-reverse;
    margin: 8px;
    margin-bottom: 80px;
  }
`;

export const WrapperLeftSide = styled.div`
  width: 40%;
  background-color: #fbfbfb;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: solid 1px ${darkBlueGrey};
  padding: 24px 64px;
  ${IS_FRAME_MOBILE} {
    border-top: solid 1px ${darkBlueGrey};
    border-top-left-radius: 0;
    border-bottom-right-radius: 8px;
    border-right: none;
    width: 94%;
    padding: 12px;
  }
`;

export const WrapperRightSide = styled.div`
  width: 60%;
  padding: 24px 64px;
  ${IS_FRAME_MOBILE} {
    padding: 12px;
    width: 94%;
  }
`;

export const WrapperTableTitle = styled.div`
  border-bottom: 1px solid ${darkBlueGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  > p {
    font-size: 24px;
    font-weight: bold;
    color: ${darkBlueGrey};
    ${IS_FRAME_MOBILE} {
      text-align: center;
      width: 100%;
    }
  }

  > span {
    font-size: 14px;
    border-bottom: 1px solid ${darkBlueGrey};
    cursor: pointer;
  }
`;

export const ProductName = styled.p`
  font-size: 18px;
  color: #e3e3e3;
  margin-block-start: 0em;
  margin-block-end: 0em;
  ${(props) =>
    props.active &&
    css`
      color: ${darkBlueGrey};
    `}
`;
export const WrapperLeftContent = styled.div`
  > ${ProductName} {
    font-weight: 600;
  }
  > div {
    margin-bottom: 13px;
    padding-left: 16px;
  }
`;

export const WrapperRightContent = styled.div`
  > p:first-child {
    font-size: 18px;
    color: #8f9198;

    margin-bottom: 1em;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #999999;
`;

export const WrapperCheckbox = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  > label {
    font-size: 18px;
    font-weight: 600;
    color: ${darkBlueGrey};
    cursor: pointer;
    > input[type="checkbox"] {
      margin-right: 22px;
      border-radius: 2px;
      border: solid 1px #8f9198;

      &:checked {
        background-color: ${darkBlueGrey};
        color: ${darkBlueGrey};
      }
    }
  }
  > span {
    margin-left: 7px;
    font-size: 14px;
    color: #000000;
    border-radius: 50%;
    border: 1px solid #000000;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }
`;

export const WrapperChildren = styled.div`
  ${(props) =>
    !props.isChild &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
      margin-left: 36px;
      margin-bottom: 24px;

      ${IS_FRAME_MOBILE} {
        grid-template-columns: repeat(1, 1fr);
      }
    `}

  ${(props) =>
    !props.open &&
    css`
      margin-bottom: 0;
    `}
`;

export const WrapperToggler = styled.div`
  border-bottom: 1px solid ${darkBlueGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  > p {
    text-transform: capitalize;
    color: ${darkBlueGrey};
    font-size: 18px;
  }
`;

export const WrapperLeftToggler = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

export const WrapperPriceUnit = styled.p`
  font-size: 14px;
  margin-top: 8px;
  color: #979797;
  > span {
    font-weight: bold;
  }
`;

export const Toggle = styled.span`
  border-radius: 50%;
  border: 1px solid ${darkBlueGrey};
  width: 23px;
  height: 23px;
  font-size: 22px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.disabled
      ? css`
          &:hover {
            background-color: lightgrey;
            cursor: pointer;
          }
        `
      : css`
          cursor: default;
          border: 0.5px solid #8f9198;
          background-color: #c2c2c2;
          color: #8f9198;
        `}
`;

export const WrapperChildrenStorage = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 36px;
  margin-top: 24px;

  > * {
    margin-bottom: 24px;
  }
`;

export const WrapperStorageTitle = styled.div`
  display: flex;

  > p {
    font-size: 18px;
    font-weight: 600;
    color: ${darkBlueGrey};
  }
  > span {
    margin-left: 7px;
    font-size: 14px;
    color: #000000;
    border-radius: 50%;
    border: 1px solid #000000;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }
`;

export const WrapperCheckout = styled.div`
  display: flex;
  justify-content: center;
`;
