import React, { useState, Fragment } from "react";
import {
  Wrapper,
  WrapperTable,
  WrapperLeftSide,
  WrapperRightSide,
  WrapperTableTitle,
  WrapperLeftContent,
  ProductName,
  WrapperRightContent,
  Divider,
  WrapperCheckbox,
  WrapperChildren,
  WrapperToggler,
  WrapperPriceUnit,
  WrapperLeftToggler,
  Toggle,
  WrapperStorageTitle,
  WrapperChildrenStorage,
  WrapperCheckout,
} from "./styles";
import { maskedPrice } from "domains/CheckoutReview/DetailSection/components/DetailCard";
import Button from "components/Button";
import { navigate } from "gatsby";

function CustomSection() {
  const [listProducts, setListProducts] = useState([
    {
      title: "Smart Workforce Management",
      open: false,
      total: 1,
      pricePerUnit: "100000",
      unit: "user",
    },
    {
      title: "Human Detection and Recognition",
      open: false,
      children: [
        {
          title: "People Counting",
          open: false,
          total: 1,
          pricePerUnit: "50000",
          unit: "engine",
        },
        {
          title: "Face Recognition",
          open: false,
          total: 1,
          pricePerUnit: "300000",
          unit: "engine",
        },
        {
          title: "Intrusion",
          open: false,
          total: 1,
          pricePerUnit: "20000",
          unit: "engine",
        },
        {
          title: "Face Mask Detection",
          open: false,
          total: 1,
          pricePerUnit: "20000",
          unit: "engine",
        },
      ],
    },
    {
      title: "Vehicle Detection and Recognition",
      open: false,
      children: [
        {
          title: "Illegal Parking",
          open: false,
          total: 1,
          pricePerUnit: "200000",
          unit: "engine",
        },
        {
          title: "Vehicle Categorization",
          open: false,
          total: 1,
          pricePerUnit: "200000",
          unit: "engine",
        },
        {
          title: "License Plate Recognition",
          open: false,
          total: 1,
          pricePerUnit: "200000",
          unit: "engine",
        },
      ],
    },
    {
      title: "Smart Device",
      open: false,
      total: 1,
      pricePerUnit: "100000",
      unit: "device",
    },
  ]);
  const [storage, setStorage] = useState({
    title: "Storage",
    total: 1,
    pricePerUnit: "200000",
    unit: "GB",
  });
  const toggleOpen = (index) => {
    const copy = [...listProducts];
    const obj = copy[index];
    obj.open = !obj.open;
    copy[index] = obj;
    setListProducts(copy);
  };
  const toggleOpenChildren = (parentIndex, childIndex) => {
    const copy = [...listProducts];
    const copyParent = [...copy[parentIndex].children];
    const obj = copyParent[childIndex];
    obj.open = !obj.open;
    copyParent[childIndex] = obj;
    copy[parentIndex].children = copyParent;
    setListProducts(copy);
  };
  const handleToggle = (type, parentIndex, childIndex = null) => {
    const copy = [...listProducts];
    switch (type) {
      case "minus":
        if (childIndex !== null) {
          const copyParent = [...copy[parentIndex].children];
          const obj = copyParent[childIndex];
          obj.total -= 1;
          copyParent[childIndex] = obj;
          copy[parentIndex].children = copyParent;
        } else {
          const copy = [...listProducts];
          const obj = copy[parentIndex];
          obj.total -= 1;
          copy[parentIndex] = obj;
        }
        break;
      case "plus":
        if (childIndex !== null) {
          const copyParent = [...copy[parentIndex].children];
          const obj = copyParent[childIndex];
          obj.total += 1;
          copyParent[childIndex] = obj;
          copy[parentIndex].children = copyParent;
        } else {
          const copy = [...listProducts];
          const obj = copy[parentIndex];
          obj.total += 1;
          copy[parentIndex] = obj;
        }
        break;
      default:
        break;
    }
    setListProducts(copy);
  };
  return (
    <Wrapper>
      <h1>Custom Plan</h1>
      <p>
        Choose the products that works for you. Only pay for the ones you need. Products include
        based on your current products selection.
      </p>
      <WrapperTable>
        <WrapperLeftSide>
          <WrapperTableTitle>
            <p>Products Include</p>
          </WrapperTableTitle>
          <WrapperLeftContent>
            <ProductName active={true}>QlueDashboard</ProductName>
            <div>
              <ProductName active={true}>Analytics</ProductName>
              <ProductName active={listProducts[0].open}>WorkGroup</ProductName>
              <ProductName active={true}>Map</ProductName>
              <ProductName active={listProducts[1].open || listProducts[2].open}>
                Live Feeds
              </ProductName>
              <ProductName active={listProducts[1].open || listProducts[2].open}>
                Attendance System
              </ProductName>
            </div>
            <ProductName active={listProducts[0].open}>QlueWork Mobile Apps</ProductName>
          </WrapperLeftContent>
        </WrapperLeftSide>
        <WrapperRightSide>
          <WrapperTableTitle>
            <p>Products Selection</p>
            <span>Select all</span>
          </WrapperTableTitle>
          <WrapperRightContent>
            <p>Select your desired products</p>
            {listProducts.map((product, idx) => (
              <Fragment key={idx}>
                <ProductCheckbox
                  title={product.title}
                  open={product.open}
                  toggleOpen={() => toggleOpen(idx)}
                >
                  {product.children ? (
                    product.children.map((cProduct, idy) => (
                      <ProductCheckbox
                        key={idy}
                        isChild={true}
                        title={cProduct.title}
                        open={cProduct.open}
                        toggleOpen={() => toggleOpenChildren(idx, idy)}
                      >
                        <ProductToggler
                          unit={cProduct.unit}
                          pricePerUnit={cProduct.pricePerUnit}
                          count={cProduct.total}
                          handleMinus={() => handleToggle("minus", idx, idy)}
                          handlePlus={() => handleToggle("plus", idx, idy)}
                        />
                      </ProductCheckbox>
                    ))
                  ) : (
                    <ProductToggler
                      unit={product.unit}
                      pricePerUnit={product.pricePerUnit}
                      count={product.total}
                      handleMinus={() => handleToggle("minus", idx)}
                      handlePlus={() => handleToggle("plus", idx)}
                    />
                  )}
                </ProductCheckbox>
                <Divider />
              </Fragment>
            ))}
            <WrapperChildrenStorage>
              <WrapperStorageTitle>
                <p>Storage</p>
                <span>?</span>
              </WrapperStorageTitle>
              <ProductToggler
                unit={storage.unit}
                pricePerUnit={storage.pricePerUnit}
                count={storage.total}
                handleMinus={() =>
                  setStorage((prev) => ({
                    ...prev,
                    total: prev.total - 1,
                  }))
                }
                handlePlus={() =>
                  setStorage((prev) => ({
                    ...prev,
                    total: prev.total + 1,
                  }))
                }
              />
            </WrapperChildrenStorage>
          </WrapperRightContent>
          <WrapperCheckout>
            <Button
              style={{ backgroundColor: "#ffda5f" }}
              onClick={() => navigate("/checkout-review")}
            >
              PROCEED TO CHECKOUT
            </Button>
          </WrapperCheckout>
        </WrapperRightSide>
      </WrapperTable>
    </Wrapper>
  );
}

function ProductToggler({ unit, count, handlePlus, handleMinus, pricePerUnit = "" }) {
  return (
    <div>
      <WrapperToggler>
        <WrapperLeftToggler>
          <Toggle disabled={count === 1} onClick={count !== 1 ? handleMinus : () => {}}>
            -
          </Toggle>
          <p>{count}</p>
          <Toggle onClick={handlePlus}>+</Toggle>
        </WrapperLeftToggler>
        <p>{unit}</p>
      </WrapperToggler>
      <WrapperPriceUnit>
        IDR<span>{maskedPrice(pricePerUnit)}</span>/{unit}/month
      </WrapperPriceUnit>
    </div>
  );
}

function ProductCheckbox({ open, toggleOpen, title, children, tooltip, isChild }) {
  return (
    <div>
      <WrapperCheckbox>
        <label>
          <input type="checkbox" checked={open} onChange={toggleOpen} />
          <span className="custom-check"></span>
          {title}
        </label>
        {!isChild && <span>?</span>}
      </WrapperCheckbox>
      <WrapperChildren isChild={isChild} open={open}>
        {open && children}
      </WrapperChildren>
    </div>
  );
}

export default CustomSection;
