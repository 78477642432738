import styled from 'styled-components'

import { paddingPage } from '../../Pricing/styles'
import { darkBlueGrey } from 'components/baseColor'

export const Wrapper = styled.div`
  ${paddingPage}
  padding-top: 6rem;
  @media only screen and (max-width: 768px) {
    padding: 2rem 0rem 0rem 0rem;
  }

  h1 {
    font-family: "Open Sans";
    font-size: 4em;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    text-align: center;
    color: ${darkBlueGrey};
    margin: 0;
    margin-bottom: 0.4em;
    @media only screen and (max-width: 768px) {
      font-size: 2em;
    }
  }

  p {
    width: 40%;
    font-family: "Open Sans";
    font-size: 1.2em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: ${darkBlueGrey};
    margin: 0 auto;
  }
`

export const WrapperClients = styled.div`
  @media only screen and (max-width: 1024px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .image-container {
    display: flex;
    justify-content: space-evenly;

    margin-top: 5em;
    @media only screen and (max-width: 1024px) {
      width: 200vw;
    }
    @media only screen and (max-width: 414px) {
      width: 300vw;
    }
    @media only screen and (max-width: 360px) {
      width: 350vw;
    }
  }

  img {
    width: 90px;
    height: 90px;
    margin-right: 150px;
    object-fit: contain;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`
