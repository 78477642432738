import React from 'react'
import SEO from 'components/seo'
import Layout from 'components/Layout'
import CustomSection from './CustomSection'
import ClientSection from 'domains/CustomPlan/ClientSection'

function CustomPlanView() {
  return (
    <>
      <SEO title="Custom Plan" />
      <Layout>
        <CustomSection />
        <ClientSection />
      </Layout>
    </>
  )
}

export default CustomPlanView
