import { css } from "styled-components";
import { IS_FRAME_MOBILE } from "utils";

export const paddingPage = css`
  padding: 0 8rem;

  ${IS_FRAME_MOBILE} {
    padding: 2em 0;
  }
`;
