import React from 'react'

import { Wrapper, WrapperClients } from './styles'

const CLIENTS = [
  'https://www.jakartamrt.co.id/wp-content/uploads/2019/01/Logo-MRT-Jakarta-Secondary.png',
  'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Logo-kemkominfo-id.svg/1200px-Logo-kemkominfo-id.svg.png',
  'https://seeklogo.com/images/P/polda-metro-jaya-logo-A4A2854AB7-seeklogo.com.png',
  'https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Coat_of_arms_of_Jakarta.svg/1200px-Coat_of_arms_of_Jakarta.svg.png',
  'https://upload.wikimedia.org/wikipedia/commons/e/e8/Lambang_Kota_Bandung.svg'
]

function ClientSection() {
  return (
    <Wrapper>
      <h1>TRUSTED BY</h1>
      <WrapperClients>
        <div className="image-container">
          { 
            CLIENTS.map((client, i) => (
              <img 
                key={ i } 
                src={ client }
                alt={ `client-${i}` }
              />
            )) 
          }
        </div>
      </WrapperClients>
    </Wrapper>
  )
}

export default ClientSection
